import { ArrowRightCircle, ArrowLeftCircle } from "react-feather"
import Slider from "react-slick"
import One from "assets/images/testimonials/Michael-Hochkofler.jpg"
import Two from "assets/images/testimonials/Sarah-Ostwald.jpg"
import Three from "assets/images/testimonials/Caroline-Vybiral.jpeg"
import Four from "assets/images/testimonials/Gwendolyn-Lorenz.jpg"
import Five from "assets/images/testimonials/Jonas-Schlenke.jpg"
import Placeholder from "assets/images/placeholder.jpg"
import * as C from "./testimonialSlider.style"

function NextArrow(props) {
  const { className, style, onClick } = props

  return (
    <ArrowRightCircle
      className={className}
      style={{ ...style }}
      onClick={onClick}
      strokeWidth={1}
    />
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <ArrowLeftCircle
      className={className}
      style={{ ...style }}
      onClick={onClick}
      strokeWidth={1}
    />
  )
}

const TestimonialSlider = () => {
  const coachFeedback = [
    {
      id: 1,
      img: One,
      title: "Foodiary Coaching Teilnehmer",
      name: "Michael Hochkofler",
      feedback:
        "Als IT-Unternehmer ist mir die Herausforderung bewusst, eine schöne und zugleich unterstützende Anwendung zu entwickeln. 👍 Foodiary hat das super umgesetzt und das Gesamtkonzept mit dem Coaching konnte mir viel Wissen vermitteln.",
    },
    {
      id: 2,
      img: Two,
      title: "Foodiary Coaching Teilnehmer",
      name: "Sarah Ostwald",
      feedback:
        "Über eine Weiterempfehlung in meinem Fitnessstudio bin ich auf Foodiary aufmerksam geworden. Das Angebot hat mir wirklich super gefallen. In meinem Coaching konnte ich nicht nur die ersten Schritte mit meinem Coach gehen, sondern auch viel über die Ernährung lernen. ☺️",
    },
    {
      id: 3,
      img: Three,
      title: "Foodiary Coaching Teilnehmer",
      name: "Caroline Vybiral",
      feedback:
        "Das Coaching bei Andrea zu buchen, war die beste Entscheidung, die ich für meine Gesundheit treffen konnte! Man lernt nicht nur etwas über gesunde Ernährung, sondern wird ganzheitlich betreut und durch die 1 zu 1 Betreuung, fühlt man sich, auch mit Problemen, nie alleine gelassen!",
    },
    // {
    //   id: 3,
    //   img: Four,
    //   title: "Foodiary Coaching Teilnehmer",
    //   name: "Gwendolyn Lorenz",
    //   feedback:
    //     "Die Betreuung mit meinem Coach lief sehr gut ab. Durch die ständige Unterstützung und den Ansprechpartner konnte ich nicht nur die Ernährung einfach umstellen, sondern auch mein Wissen erweitern. 👍",
    // },
    {
      id: 3,
      img: Five,
      title: "Foodiary Coaching Teilnehmer",
      name: "Jonas Schlenker",
      feedback:
        "12 Wochen vergingen im Flug! Zunächst möchte ich die hervorragende Betreuung hervorheben, mein Berater hat sich immer die Zeit für mich genommen. Die App mit den Rezepten ist wirklich klasse. Dadurch konnte ich einfach planen und das Essen, was mir geschmeckt hat.",
    },
  ]

  const slidesToShow = coachFeedback?.length === 1 ? 1 : 2

  const settings = {
    className: "",
    slidesToShow,
    speed: 500,
    nextArrow: <NextArrow className="" />,
    prevArrow: <PrevArrow className="" />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      }
    ],
  }

  return (
    <>
      <C.Title
        id="headline-81-31"
        className="ct-headline foodiary-h2 color-dark text-4xl mt-8 mb-12 w-full"
      >
        Das sagen Klienten
      </C.Title>
      <C.SliderCard className="generic-slider">
        <Slider className="slick-slider" {...settings}>
          {coachFeedback?.map((item, i) => (
            <div className="slick-item" key={i}>
              <div className="sliderInner px-8 py-12">
                <C.UserContent>
                  <h2
                    id="text_block-87-31"
                    className="ct-text-block color-dark text-xl font-medium mb-2"
                  >
                    {item?.name}
                  </h2>
                  <small
                    id="text_block-88-31"
                    className="ct-text-block color-primary"
                  >
                    {item?.title}
                  </small>
                </C.UserContent>
                <C.UserDescription
                  id="text_block-89-31"
                  className="ct-text-block text-base color-paragraph text-left mt-10 mb-0"
                >
                  {item?.feedback}
                </C.UserDescription>
                <C.UserProfile>
                  <img
                    id="image-91-31"
                    className="ct-image"
                    src={item?.img}
                    alt="user"
                  />
                </C.UserProfile>
              </div>
            </div>
          ))}
        </Slider>
      </C.SliderCard>
    </>
  )
}

export default TestimonialSlider
